import React from "react"
import { graphql } from "gatsby"
import { Layout, Hero, Posts, Works, SEO } from "../components"
import works from "../constants/works"

const IndexPage = ({ data }) => {
  const {
    allMdx: { nodes: posts },
  } = data

  return (
    <Layout>
      <SEO title="ホーム" />
      <Hero />
      <Works works={works} title="作品" />
      <Posts posts={posts} title="新着投稿" />
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          title
          author
          category
          date(formatString: "MMM Do,YYYY ")
          slug
          readTime
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
        body
      }
    }
  }
`

export default IndexPage
